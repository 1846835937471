// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
// helpers
import { getCookie, setCookie } from "helpers/cookie";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
// component
import WrapperCartPage from "../component/WrapperCartPage";

const mapStateToProps = (state) => ({
  cart: state.cart,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateCart: (...args) => dispatch(Actions.updateCart(...args)),
  updateCartGood: (...args) => dispatch(Actions.updateCartGood(...args)),
  deleteCartGood: (...args) => dispatch(Actions.deleteCartGood(...args)),
  deleteCartCoupon: (...args) => dispatch(Actions.deleteCartCoupon(...args)),
  getCurrentCart: (...args) => dispatch(Actions.getCurrentCart(...args)),
  validateCart: (...args) => dispatch(Actions.validateCart(...args)),
});

class CartPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    updateCartGood: PropTypes.func,
    updateCart: PropTypes.func,
    deleteCartGood: PropTypes.func,
    deleteCartCoupon: PropTypes.func,
    getCurrentCart: PropTypes.func,
    validateCart: PropTypes.func,
    cart: PropTypes.object,
    user: PropTypes.object,
    cookies: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
      init: false,
    };
    this.routine(this.props);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.location && !this.state.init) {
  //     const nextUrlParams = getJsonFromUrl(nextProps.location);
  //     if (nextUrlParams.goodID) {
  //       this.routine(nextProps);
  //     }
  //   }
  // }

  async refresh() {
    //eslint-disable-line
    await this.getCurrentCart()();
    this.setState({
      refreshKey: Date.now(),
      loading: false,
      init: true,
    });
  }

  initCookieCart() {
    const { cookies } = this.props;
    const { urlParams } = this.state;

    setCookie(
      cookies,
      "cart",
      JSON.stringify({
        goods: [],
        coupons: urlParams.coupon ? [{ token: urlParams.coupon }] : [],
      })
    );
  }

  async routine(nextProps) {
    const { init } = this.state;
    const r = await this.getCurrentCart()();
    let cart;
    if (r.success) {
      cart = r.payload;
    } else {
      cart = JSON.parse(getCookie("cart"));
    }

    const nextUrlParams = getJsonFromUrl(window.location);
    if (!init) {
      if (nextProps.user && nextProps.user.uuid) {
        if (cart.id) {
          this.state.init = true; // eslint-disable-line
          if (nextUrlParams.coupon) {
            cart.coupons = [{ token: nextUrlParams.coupon }];
          }
          if (nextUrlParams.goodID && nextUrlParams.pricingID) {
            cart.goods.push({
              id: Number(nextUrlParams.goodID),
              quantity: 1,
              pricingID: Number(nextUrlParams.pricingID),
            });
          }

          this.updateCart()(cart.id, cart).then(this.refresh.bind(this));
        }
      } else {
        this.state.init = true; // eslint-disable-line
        if (nextUrlParams.coupon) {
          cart.coupons = [{ token: nextUrlParams.coupon }];
        }
        if (nextUrlParams.goodID && nextUrlParams.pricingID) {
          cart.goods.push({
            id: Number(nextUrlParams.goodID),
            quantity: 1,
            pricingID: Number(nextUrlParams.pricingID),
          });
        }

        this.updateCart()(undefined, cart).then(this.refresh.bind(this));
      }
    }
  }

  getCurrentCart() {
    const { user, getCurrentCart, validateCart, cookies } = this.props;
    const { urlParams } = this.state;
    if (user && user.uuid) {
      return getCurrentCart;
    }

    return () => {
      let cart;
      try {
        cart = JSON.parse(getCookie("cart"));
      } catch (error) {
        this.initCookieCart();
        cart = JSON.parse(getCookie("cart"));
      }

      if (
        urlParams.coupon &&
        cart.coupons.findIndex((c) => c.token === urlParams.coupon) === -1
      )
        cart.coupons.push({ token: urlParams.coupon });

      let r = validateCart(cart);
      r.then((c) => {
        if (c.success) {
          const reducedCart = this.getReducedCart(c.payload);
          setCookie(cookies, "cart", JSON.stringify(reducedCart));
        }
      });
      return r;
    };
  }

  getReducedCart(complexCart) {
    const reducedCart = {
      goods: [],
      coupons: [],
    };
    complexCart.coupons.forEach((c) =>
      reducedCart.coupons.push({
        token: c.token,
      })
    );
    complexCart.goods.forEach((g) =>
      reducedCart.goods.push({
        pricingID: g.pricingID,
        id: g.id,
        quantity: g.quantity,
      })
    );

    return reducedCart;
  }

  async emptyCart() {
    const r = await this.getCurrentCart()();
    var cart;
    if (r.success) {
      cart = r.payload;
    } else {
      cart = JSON.parse(getCookie("cart"));
    }
    cart.goods = [];
    cart.coupons = [];
    cart.discount = 0;
    cart.subTotal = 0;
    cart.total = 0;

    if (cart.id) {
      this.updateCart()(cart.id, cart).then(this.refresh.bind(this));
    } else {
      this.updateCart()(undefined, cart);
      this.refresh();
    }
  }

  updateCart() {
    const { user, updateCart, cookies } = this.props;
    if (user && user.uuid) return updateCart;

    return (cartID, cart) => {
      return new Promise((a, r) => {
        const reducedCart = this.getReducedCart(cart);
        setCookie(cookies, "cart", JSON.stringify(reducedCart));
        a();
      });
    };
  }

  render() {
    const { location, history, cart } = this.props;

    const { urlParams, refreshKey, loading } = this.state;

    return (
      <WrapperCartPage
        history={history}
        location={location}
        urlParams={urlParams}
        updateCart={this.updateCart()}
        loading={loading}
        refreshKey={refreshKey}
        emptyCart={this.emptyCart.bind(this)}
        refresh={this.refresh.bind(this)}
        cart={cart}
      />
    );
  }
}
export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(CartPage)
);
